import {
  Layout,
  Typography,
  Avatar,
  Dropdown,
  Button,
  notification,
} from 'antd';
import { SHA256 } from 'crypto-js';
import Identicon from 'identicon.js';
import { DownOutlined } from '@ant-design/icons';
import { logout } from '../../Redux/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../utils/API';

const { Header } = Layout;
const { Title } = Typography;

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const userAcc = localStorage.getItem('swype_user_acc');
  const key = 'logout';
  // auth related redux variables
  const { token } = useSelector((state) => state.auth);

  const generateAvatar = (seed) => {
    const options = {
      size: 80, // Adjust the size of the identicon image
    };
    const hash = SHA256(seed).toString();
    const data = new Identicon(hash.slice(0, 15), options).toString();
    return 'data:image/png;base64,' + data;
  };

  const handleLogout = async () => {
    try {
      await API.post(
        '/api/user/logout',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      ).then((res) => {
        if (res.status === 200) {
          notification['success']({
            key,
            message: res.statusText,
            description: res.data.message,
          });
          dispatch(logout());
        }
      });
    } catch (error) {
      notification['success']({
        key,
        message: 'OK',
        description: 'Logout successful.',
      });
      dispatch(logout());
    }
  };

  const items = [
    {
      key: '1',
      label: (
        <Button className="w-100" type="ghost" onClick={() => handleLogout()}>
          Logout
        </Button>
      ),
    },
  ];

  return (
    <Header className="fixed-top" style={{ zIndex: 1 }}>
      <div className="d-flex justify-content-end">
        <div>
          <Avatar
            size={30}
            style={{ marginRight: '10px' }}
            src={generateAvatar(userAcc)}
          />
        </div>
        <div>
          <Dropdown
            menu={{
              items,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <div className="d-flex">
                <Title
                  level={5}
                  className="text-light"
                  style={{ marginTop: '20px' }}
                >
                  {userAcc}
                </Title>
                <DownOutlined
                  style={{
                    color: 'white',
                    marginTop: '16px',
                    marginLeft: '10px',
                  }}
                />
              </div>
            </a>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
