import React, { useState, useEffect } from 'react';
import { Card, Statistic, Row, Col, notification } from 'antd';
import API from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';
import CountUp from 'react-countup';
import RecentCampaigns from '../../components/Admin/Dashboard/RecentCampaigns';
import RecentAds from '../../components/Admin/Dashboard/RecentAds';
import { motion } from 'framer-motion';
import { container, item } from '../../utils/FramerMotion';

const Dashboard = () => {
  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const key = 'adCampaign_stats';
  const userAcc = localStorage.getItem('swype_user_acc');

  const [adCampaignCount, setAdCampaignCount] = useState('');
  const [adCampaignCountLoading, setAdCampaignCountLoading] = useState(false);

  const formatter = (value) => <CountUp end={value} separator="," />;

  useEffect(() => {
    fetchAdCampaignCount();
  }, []);

  const fetchAdCampaignCount = async () => {
    try {
      setAdCampaignCountLoading(true);
      await API.get('/api/dashboard', {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (res.status === 200) {
          setAdCampaignCount(res.data);
          setAdCampaignCountLoading(false);
        }
      });
    } catch (error) {
      setAdCampaignCountLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  return (
    <motion.div
      className="container"
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <div className="p-4">
        <motion.div variants={item}>
          <Card className="mb-4">
            <h3>
              Hello <span style={{ color: '#2f54eb' }}>{userAcc}</span> 👋
            </h3>
            <span className="text-secondary">
              Let's create more ads and campaigns!
            </span>
          </Card>
        </motion.div>

        <motion.div variants={item}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card hoverable>
                <Statistic
                  title="Number of Ads 🖼️"
                  value={adCampaignCount?.ads}
                  formatter={formatter}
                  loading={adCampaignCountLoading}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Card hoverable>
                <Statistic
                  title="Number of Campaigns 🚀"
                  value={adCampaignCount?.campaigns}
                  formatter={formatter}
                  loading={adCampaignCountLoading}
                />
              </Card>
            </Col>
          </Row>
        </motion.div>

        <div className="mt-4">
          <motion.div variants={item}>
            <RecentCampaigns />
          </motion.div>
        </div>

        <div className="mt-4">
          <motion.div variants={item}>
            <RecentAds />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Dashboard;
