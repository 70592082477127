import React from 'react';
import { Card } from 'antd';
import RecentCampaigns from '../../components/Client/Dashboard/RecentCampaigns';
import RecentAds from '../../components/Client/Dashboard/RecentAds';
import { motion } from 'framer-motion';

const DashboardClient = () => {
  const userAcc = localStorage.getItem('swype_user_acc');

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="container"
      variants={container}
      initial="hidden"
      animate="visible"
    >
      <div className="p-4">
        <motion.div variants={item}>
          <Card className="mb-4">
            <h3>
              Hello <span style={{ color: '#2f54eb' }}>{userAcc}</span> 👋
            </h3>
            <span className="text-secondary">
              Let's see what happens with your advertisements and campaigns!
            </span>
          </Card>
        </motion.div>

        <div className="mt-4">
          <motion.div variants={item}>
            <RecentCampaigns />
          </motion.div>
        </div>

        <div className="mt-4">
          <motion.div variants={item}>
            <RecentAds />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default DashboardClient;
