import React, { useState, useEffect } from 'react';
import { Button, Card, Tooltip, notification, Spin } from 'antd';
import API from '../../utils/API';
import BreadcrumbComponent from '../../components/AllAds/BreadcrumbComponent';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { EditFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';
import { motion } from 'framer-motion';
import { container, item } from '../../utils/FramerMotion';

const AllAds = () => {
  const { Meta } = Card;
  const key = 'fetch_ads';

  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(false);

  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAds();
  }, []);

  const fetchAds = async () => {
    setLoading(true);
    try {
      await API.get('/api/ads', {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        setAds(res.data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  return (
    <div>
      <motion.div
        className="container"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={item}>
          <BreadcrumbComponent />
        </motion.div>
        <div className="mt-4">
          <motion.div variants={item}>
            <Card>
              <div className="mb-2">
                <h5>All Advertisements 🖼️</h5>
                <span className="text-muted">
                  For see all your ads and edit ads
                </span>
              </div>
            </Card>
          </motion.div>
        </div>

        {loading ? (
          <div className="text-center mt-5">
            <Spin />
          </div>
        ) : (
          <motion.div variants={item}>
            <Row>
              {ads.map((ad) => (
                <Col
                  xxl="3"
                  xl="3"
                  lg="3"
                  md="4"
                  sm="6"
                  xs="12"
                  className="mt-4"
                  key={ad.id}
                >
                  <Card
                    hoverable
                    cover={
                      <img
                        alt="example"
                        src={ad?.img_url}
                        style={{ height: '250px' }}
                      />
                    }
                  >
                    <Meta title={ad?.cta_type} description={ad?.cta_url} />

                    <div className="mt-3 d-flex justify-content-around">
                      <Tooltip title="Edit Advertisment">
                        <Link to={`/admin/advertisments/edit/${ad?.id}`}>
                          <Button type="primary">
                            <EditFilled />
                          </Button>
                        </Link>
                      </Tooltip>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default AllAds;
