import React, { useState, useEffect } from 'react';
import API from '../../utils/API';
import { Card, Checkbox, Button, notification, Spin } from 'antd';
import BreadcrumbComponent from '../../components/Admin/AdCampaignMapper.jsx/BreadcrumbComponent';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { container, item } from '../../utils/FramerMotion';

const AdCampaignMapper = () => {
  const related_data_key = 'fetch_related_data';
  const all_ads_key = 'fetch_all_ads';
  const ad_mapping_key = 'ad_mapping';
  // get url query params
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const campaignName = queryParams.get('name');
  const campaignId = queryParams.get('campaign');

  // mapped ads ids are stored in this Set
  const mappedIdSet = new Set();
  const [relatedAds, setRelatedAds] = useState([]);
  const [allAds, setAllAds] = useState([]);

  const [mappingProcessing, setMappingProcessing] = useState(false); // loading state when submitting
  const [isLoadingRelatedAds, setIsLoadingRelatedAds] = useState(false);
  const [isLoadingAllAds, setIsLoadingAllAds] = useState(false);

  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // TODO: get mapped ads data from the backend using campaignId
  useEffect(() => {
    const fetchRelatedAds = async () => {
      try {
        setIsLoadingRelatedAds(true);
        await API.get(`/api/mapper/campaign/${campaignId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }).then((res) => {
          if (res.status === 200) {
            setRelatedAds(res.data);
            setIsLoadingRelatedAds(false);
          }
        });
      } catch (error) {
        setIsLoadingRelatedAds(false);
        notification['error']({
          related_data_key,
          message: error.response.statusText,
          description: error.response.data.message,
        });
        if (error.response.status === 401) {
          // logout user
          dispatch(logout());
        }
      }
    };
    fetchRelatedAds();
  }, [campaignId]);

  // TODO: get all ads from the backend using campaignId
  useEffect(() => {
    const fetchAllAds = async () => {
      setIsLoadingAllAds(true);
      try {
        await API.get('/api/ads', {
          headers: { Authorization: `Bearer ${token}` },
        }).then((res) => {
          setAllAds(res.data);
          setIsLoadingAllAds(false);
        });
      } catch (error) {
        setIsLoadingAllAds(false);
        notification['error']({
          all_ads_key,
          message: error.response.statusText,
          description: error.response.data.message,
        });
        if (error.response.status === 401) {
          // logout user
          dispatch(logout());
        }
      }
    };
    fetchAllAds();
  }, []);

  // to handle checkboxes
  const onChange = (e, id) => {
    if (e.target.checked) {
      mappedIdSet.add(id);
    } else {
      mappedIdSet.delete(id);
    }
  };

  // check and show already added ads marked as true
  const getRelation = (id) => {
    for (var index = 0; index < relatedAds.length; ++index) {
      var advertisement = relatedAds[index];
      if (advertisement.id === id) {
        mappedIdSet.add(id);
        return true;
      }
    }
  };

  // TODO: create mapped ad data after submitting
  const handleAdMapping = async () => {
    setMappingProcessing(true);
    const AdIdArray = Array.from(mappedIdSet);
    try {
      await API.post(
        '/api/mapper/',
        { ad_ids: AdIdArray, campaign_id: campaignId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      ).then((res) => {
        if (res.status === 202) {
          notification['success']({
            ad_mapping_key,
            message: 'Success',
            description: 'Successfully Added Advertisements to the Campaign!',
          });
          setMappingProcessing(false);
          navigate('/admin/campaigns');
        }
      });
    } catch (error) {
      setMappingProcessing(false);
      notification['error']({
        ad_mapping_key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  return (
    <div>
      <BreadcrumbComponent />

      <div className="mt-4">
        <Card>
          <div className="mb-2">
            <h5>Add Advertisements for {campaignName} 😀</h5>
            <span className="text-muted">
              Select advertisements you are going to use for {campaignName}
            </span>
          </div>

          {isLoadingRelatedAds || isLoadingAllAds ? (
            <div className="text-center mt-5">
              <Spin />
            </div>
          ) : (
            <motion.div
              className="container"
              variants={container}
              initial="hidden"
              animate="visible"
            >
              <motion.div variants={item}>
                <Row>
                  {allAds.map((adData) => (
                    <Col
                      xxl="2"
                      xl="2"
                      lg="2"
                      md="3"
                      sm="3"
                      xs="3"
                      key={adData?.id}
                      className="mt-4"
                    >
                      <Checkbox
                        style={{
                          position: 'absolute',
                          marginLeft: '5px',
                          marginTop: '5px',
                        }}
                        defaultChecked={getRelation(adData.id)}
                        onChange={(e) => onChange(e, adData.id)}
                      />
                      <img
                        alt="advertisement"
                        src={adData?.img_url}
                        style={{ height: '220px' }}
                      />
                    </Col>
                  ))}
                </Row>
              </motion.div>

              <div className="mt-5 text-center">
                <motion.div variants={item}>
                  <Button
                    type="primary"
                    onClick={handleAdMapping}
                    loading={mappingProcessing}
                  >
                    Update
                  </Button>
                </motion.div>
              </div>
            </motion.div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default AdCampaignMapper;
