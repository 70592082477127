import React, { useState, useEffect } from 'react';
import { Button, Card, Modal, Tooltip, notification, Spin } from 'antd';
import BreadcrumbComponent from '../../components/AllCampaigns/BreadcrumbComponent';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import QRComponent from '../../components/Admin/CreateCampaign/QRComponent';
import { QrcodeOutlined, FundViewOutlined } from '@ant-design/icons';
import API from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';
import { motion } from 'framer-motion';
import { container, item } from '../../utils/FramerMotion';
import { EnvironmentFilled } from '@ant-design/icons';

const AllClientCampaigns = () => {
  const { Meta } = Card;
  const key = 'fetch_campaigns';

  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // modal related data
  const [open, setOpen] = useState(false);
  const [activeCampaignId, setActiveCampaignId] = useState(null);
  const [activeCampaignName, setActiveCampaignName] = useState(null);
  const [loading, setLoading] = useState(false);

  const showModal = (campaignId, campaignName) => {
    setActiveCampaignId(campaignId);
    setActiveCampaignName(campaignName);
    if (campaignId) {
      setOpen(true);
    }
  };

  const handleCancel = () => {
    setActiveCampaignId(null);
    setActiveCampaignName(null);
    setOpen(false);
  };

  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      await API.get('/api/campaigns', {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        setCampaigns(res.data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  return (
    <div>
      <motion.div
        className="container"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={item}>
          <BreadcrumbComponent />
        </motion.div>
        <div className="mt-4">
          <motion.div variants={item}>
            <Card>
              <div className="mb-2">
                <h5>All Campaigns 🚀</h5>
                <span className="text-muted">
                  See campaigns & live advertisements for campaigns
                </span>
              </div>
            </Card>
          </motion.div>
        </div>

        {loading ? (
          <div className="text-center mt-5">
            <Spin />
          </div>
        ) : (
          <motion.div variants={item}>
            <Row>
              {campaigns.map((campaign) => (
                <Col
                  xxl="3"
                  xl="3"
                  lg="3"
                  md="4"
                  sm="6"
                  xs="12"
                  className="mt-4"
                  key={campaign.id}
                >
                  <Card
                    hoverable
                    cover={
                      <img
                        alt="example"
                        src={campaign?.welcome_card_url}
                        style={{ height: '250px' }}
                      />
                    }
                  >
                    <Meta
                      title={campaign?.name}
                      description={
                        <>
                          <span>{campaign?.description}</span>
                          <br />
                          <span className="text-dark">
                            <EnvironmentFilled />
                            {` `}
                            {campaign?.location}
                          </span>
                        </>
                      }
                    />

                    <Row className="mt-3">
                      <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="6">
                        <Tooltip title="View QR">
                          <Button
                            type="ghost"
                            className="w-100"
                            style={{ background: '#4096ff', color: 'white' }}
                            onClick={() =>
                              showModal(campaign?.id, campaign?.name)
                            }
                          >
                            <QrcodeOutlined />
                          </Button>
                        </Tooltip>
                      </Col>

                      <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="6">
                        <Tooltip title="View Analytics">
                          <Link
                            to={`/client/campaign/analytics?campaign=${campaign?.id}&name=${campaign?.name}`}
                          >
                            <Button
                              type="ghost"
                              className="w-100"
                              style={{ background: '#10239e', color: 'white' }}
                            >
                              <FundViewOutlined />
                            </Button>
                          </Link>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </motion.div>
        )}
      </motion.div>

      <Modal
        open={open}
        title={`Download QR Code for ${activeCampaignName}`}
        onCancel={handleCancel}
        footer={false}
      >
        <QRComponent campaignId={activeCampaignId} />
      </Modal>
    </div>
  );
};

export default AllClientCampaigns;
