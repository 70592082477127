import { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import Pages from './Pages';

// layout components
import SiderComponent from './components/Layout/SiderComponent';
import HeaderComponent from './components/Layout/HeaderComponent';
import GuestHeader from './components/Layout/GuestHeader';
import FooterComponent from './components/Layout/FooterComponent';

const { Content } = Layout;

const LayoutView = () => {
  const { pathname } = useLocation();
  const [guestView, setGuestView] = useState(true);
  const [marginLeft, setMarginLeft] = useState('')

  // this hook will determine which layout to show
  useEffect(() => {
    if (pathname === '/login') {
      setGuestView(true);
      setMarginLeft('')
    } else {
      setGuestView(false);
      setMarginLeft('200px')
    }
  }, [pathname]);

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      {!guestView && <SiderComponent />}

      <Layout>
        {!guestView && <HeaderComponent />}

        {guestView && <GuestHeader />}

        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <div
            style={{
              marginLeft: marginLeft,
              marginTop: '80px'
            }}
          >
            <Pages />
          </div>
        </Content>

        <FooterComponent />
      </Layout>
    </Layout>
  );
};

export default LayoutView;
