import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BreadcrumbComponent from '../../components/Admin/UpdateAd/BreadcrumbComponent';
import FormComponent from '../../components/Admin/CreateAd/FormComponent';
import { Card, notification, Spin, Popconfirm } from 'antd';
import API from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { DeleteFilled } from '@ant-design/icons';

const UpdateAd = () => {
  const key = 'ad_fetch';
  const update_key = 'update_ad';
  const { adId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  // advertisement data object
  const [adData, setAdData] = useState('');
  const [adDataLoading, setAdDataLoading] = useState(false);

  const [isAdDeleting, setIsAdDeleting] = useState(false);

  useEffect(() => {
    fetchAdData();
  }, [adId, dispatch, token]);

  const fetchAdData = async () => {
    setAdDataLoading(true);
    try {
      await API.get(`/api/ads/${adId}`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (res.status === 200) {
          setAdData(res.data);
          setAdDataLoading(false);
        }
      });
    } catch (error) {
      setAdDataLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.put(
        `/api/ads/${adId}`,
        {
          img_url: adData.img_url,
          cta_type: adData.cta_type,
          cta_url: adData.cta_url,
          ad_active: adData.ad_active,
          client_id: adData.client_id,
          button_text: adData.button_text
        },
        { headers: { Authorization: `Bearer ${token}` } }
      ).then((res) => {
        if (res.status === 200) {
          notification['success']({
            update_key,
            message: 'Success',
            description: 'Successfully Updated!',
          });
          setLoading(false);
          navigate('/admin/advertisements');
        }
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        update_key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  const confirm = (e) => {
    deleteAd();
  };

  const cancel = (e) => {};

  const deleteAd = async () => {
    try {
      setIsAdDeleting(true);
      await API.delete(`/api/ads/${adId}`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (res.status === 200) {
          notification['success']({
            update_key,
            message: 'Success',
            description: 'Successfully Deleted the Ad!',
          });
          setIsAdDeleting(false);
          navigate('/admin/advertisements');
        }
      });
    } catch (error) {
      setIsAdDeleting(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  return (
    <div>
      <BreadcrumbComponent />

      <div className="mt-4">
        <Card>
          <div className="d-flex justify-content-between">
            <div className="mb-4">
              <h5>Update an Advertisement 🖼️</h5>
              <span className="text-muted">
                Please update the advertisement information below!
              </span>
            </div>

            <div>
              {isAdDeleting ? (
                <Spin />
              ) : (
                <Popconfirm
                  title="Delete the Advertisement"
                  description="Are you sure to delete this advertisement?"
                  onConfirm={confirm}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteFilled
                    style={{
                      fontSize: '18px',
                      color: '#f5222d',
                      cursor: 'pointer',
                    }}
                  />
                </Popconfirm>
              )}
            </div>
          </div>

          {adDataLoading ? (
            <div className="text-center mt-5">
              <Spin />
            </div>
          ) : (
            <FormComponent
              adData={adData}
              setAdData={setAdData}
              loading={loading}
              handleSubmit={handleSubmit}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default UpdateAd;
