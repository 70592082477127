import { Menu } from 'antd';
import { items } from '../../utils/ClientMenuItems';
import { useNavigate, useLocation } from 'react-router-dom';

const ClientMenuComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // navigate to the relevant route
  const onClick = (e) => {
    navigate(e.key);
  };

  return (
    <Menu
      theme="dark"
      selectedKeys={location.pathname}
      mode="inline"
      onClick={onClick}
      items={items}
    />
  );
};

export default ClientMenuComponent;

