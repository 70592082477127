import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// admin pages
import Dashboard from './pages/admin/Dashboard';
import Login from './pages/auth/Login';
import NotFound from './pages/auth/NotFound';
import CreateUser from './pages/admin/CreateUser';
import CreateAd from './pages/admin/CreateAd';
import UpdateAd from './pages/admin/UpdateAd';
import CreateCampaign from './pages/admin/CreateCampaign';
import UpdateCampaign from './pages/admin/UpdateCampaign';
import AllCampaigns from './pages/admin/AllCampaigns';
import AdCampaignMapper from './pages/admin/AdCampaignMapper';
import AllAds from './pages/admin/AllAds';
import ViewCampaignAnalytics from './pages/admin/ViewCampaignAnalytics';
import ViewAdAnalytics from './pages/admin/ViewAdAnalytics';
import AllUsers from './pages/admin/AllUsers';

// client pages
import DashboardClient from './pages/client/DashboardClient';
import AllClientAds from './pages/client/AllClientAds';
import AllClientCampaigns from './pages/client/AllClientCampaigns';


function Pages() {

  const { token, userRole } = useSelector((state) => state.auth)
  
  return (
    <Routes>
      <Route path="/admin/dashboard" exact element={ (token && userRole === 'ROLE_ADMIN') ? <Dashboard /> : <Navigate to="/login" />} />
      <Route path="/admin/create-user" exact element={ (token && userRole === 'ROLE_ADMIN') ? <CreateUser /> : <Navigate to="/login" />} />
      <Route path="/admin/create-advertisement" exact element={(token && userRole === 'ROLE_ADMIN') ? <CreateAd /> : <Navigate to="/login" />} />
      <Route path="/admin/advertisments/edit/:adId" exact element={(token && userRole === 'ROLE_ADMIN') ? <UpdateAd /> : <Navigate to="/login" />} />
      <Route path="/admin/campaigns/edit/:campaignId" exact element={(token && userRole === 'ROLE_ADMIN') ? <UpdateCampaign /> : <Navigate to="/login" />} />
      <Route path="/admin/create-campaign" exact element={ (token && userRole === 'ROLE_ADMIN') ? <CreateCampaign /> : <Navigate to="/login" />} />
      <Route path="/admin/campaigns" exact element={ (token && userRole === 'ROLE_ADMIN') ? <AllCampaigns /> : <Navigate to="/login" />} />
      <Route path="/admin/campaign/add-advertisements" exact element={(token && userRole === 'ROLE_ADMIN') ? <AdCampaignMapper /> : <Navigate to="/login" />} />
      <Route path="/admin/campaign/analytics" exact element={(token && userRole === 'ROLE_ADMIN') ? <ViewCampaignAnalytics /> : <Navigate to="/login" />} />
      <Route path="/admin/advertisement/analytics" exact element={(token && userRole === 'ROLE_ADMIN') ? <ViewAdAnalytics /> : <Navigate to="/login" />} />
      <Route path="/admin/advertisements" exact element={ (token && userRole === 'ROLE_ADMIN') ?  <AllAds /> : <Navigate to="/login" />} />
      <Route path="/admin/users" exact element={ (token && userRole === 'ROLE_ADMIN') ?  <AllUsers /> : <Navigate to="/login" />} />

      <Route path="/client/dashboard" exact element={ (token && userRole === 'ROLE_CLIENT') ?  <DashboardClient /> : <Navigate to="/login" />} />
      <Route path="/client/advertisements" exact element={ (token && userRole === 'ROLE_CLIENT') ?  <AllClientAds /> : <Navigate to="/login" />} />
      <Route path="/client/campaigns" exact element={ (token && userRole === 'ROLE_CLIENT') ?  <AllClientCampaigns /> : <Navigate to="/login" />} />
      <Route path="/client/campaign/analytics" exact element={(token && userRole === 'ROLE_CLIENT') ? <ViewCampaignAnalytics /> : <Navigate to="/login" />} />
      
      <Route path="/" exact element={userRole === 'ROLE_ADMIN' ? <Navigate to="/admin/dashboard" /> : <Navigate to="/client/dashboard" />} />

      <Route path="/login" exact element={<Login />} />
      <Route path="*" element={ token ? <NotFound /> : <Navigate to="/login" />} />
    </Routes>
  );
}

export default Pages;
