import { Layout } from 'antd';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const { Footer } = Layout;

const FooterComponent = () => {

  const { pathname } = useLocation();
  const [marginLeft, setMarginLeft] = useState('')

    // this hook will determine which layout to show
    useEffect(() => {
      if (pathname === '/login') {
        setMarginLeft('')
      } else {
        setMarginLeft('200px')
      }
    }, [pathname]);
  

  return (
    <Footer
      style={{
        textAlign: 'center',
        marginLeft: marginLeft,
      }}
    >
      Richardson Outdoor Advertising ©{new Date().getFullYear()} Developed by{' '}
      <a href="https://imigap.com" target="_blank" rel="noreferrer">
        Imigap
      </a>
    </Footer>
  );
};

export default FooterComponent;
