import {
  PictureFilled,
  FolderFilled,
  AppstoreFilled
} from '@ant-design/icons';

// only returns menu items related to user type
function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
}

export const items = [
  getItem('Dashboard', '/client/dashboard', <AppstoreFilled />),
  getItem('Ads', '/client/advertisements', <PictureFilled />),
  getItem('Campaigns', '/client/campaigns', <FolderFilled />),
];
