import React, { useState, useEffect } from 'react';
import BreadcrumbComponent from '../../components/ViewCampaignAnalytics/BreadcrumbComponent';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Tooltip,
  Alert,
  Drawer,
  DatePicker,
  Button,
  notification,
  Tag,
  Spin,
} from 'antd';
import { SettingFilled } from '@ant-design/icons';
import API from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';
import { Col, Row } from 'reactstrap';
import { motion } from 'framer-motion';
import { container, item } from '../../utils/FramerMotion';

const ViewCampaignAnalytics = () => {
  // get url query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const campaignName = queryParams.get('name');
  const campaignId = queryParams.get('campaign');
  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const key = 'analytics_fetch';

  // analytics response data
  const [isLoading, setIsLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);

  // drawer related variables
  const [open, setOpen] = useState(false);

  // range picker related data
  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onChange = (date, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  useEffect(() => {
    fetchAnalyticsData();
  }, [campaignId]);

  const fetchAnalyticsData = async () => {
    try {
      setIsLoading(true);
      await API.post(
        '/api/analytical-data/campaigns',
        {
          campaign_id: campaignId,
          start_time_data: startDate,
          end_time_data: endDate,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      ).then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setAnalyticsData(res.data);
          setIsLoading(false);
          onClose();
        }
      });
    } catch (error) {
      setIsLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  return (
    <div>
      <BreadcrumbComponent />

      <div className="mt-4">
        <Card>
          <div className="mb-2">
            <div className="d-flex justify-content-between">
              <div>
                <h5>View Analytics for {campaignName} 📊</h5>
                <span className="text-muted">
                  Select date range to filter analytics data. Click on{' '}
                  <SettingFilled /> icon to select date range
                </span>
              </div>

              <div>
                <Tooltip title="Select Dates Range">
                  <SettingFilled
                    style={{ fontSize: '25px', cursor: 'pointer' }}
                    onClick={showDrawer}
                  />
                </Tooltip>
              </div>
            </div>

            {isLoading ? (
              <div className="text-center mt-5">
                <Spin />
              </div>
            ) : (
              <motion.div
                className="container"
                variants={container}
                initial="hidden"
                animate="visible"
              >
                <div>
                  {analyticsData.length > 0 && (
                    <div className="mt-5">
                      <motion.div variants={item}>
                        <Row>
                          {analyticsData.map((data) => (
                            <Col
                              xxl="3"
                              xl="3"
                              lg="3"
                              md="4"
                              sm="6"
                              xs="12"
                              className="mt-4"
                              key={data?.ad_id}
                            >
                              <Card
                                hoverable
                                cover={
                                  <img
                                    alt="example"
                                    src={data?.img_url}
                                    style={{ height: '250px' }}
                                  />
                                }
                              >
                                <Row>
                                  <Col>
                                    <Tag
                                      bordered={false}
                                      color="red"
                                      className="p-1"
                                    >
                                      VIEWS: {data?.views}
                                    </Tag>
                                  </Col>
                                  <Col>
                                    <Tag
                                      bordered={false}
                                      color="geekblue"
                                      className="p-1"
                                    >
                                      CTA CLICKS: {data?.cta_clicks}
                                    </Tag>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </motion.div>
                    </div>
                  )}
                </div>
              </motion.div>
            )}
          </div>
        </Card>

        {analyticsData.length === 0 && !isLoading && (
          <motion.div
            className="container"
            variants={container}
            initial="hidden"
            animate="visible"
          >
            <motion.div variants={item}>
              <Alert
                className="mt-4"
                description="Currently no data available for this campaign for selected dates range."
                type="info"
                showIcon
              />
            </motion.div>
          </motion.div>
        )}

        <Drawer
          title="Select Date Range"
          placement="right"
          onClose={onClose}
          open={open}
          width={450}
        >
          <RangePicker showTime onChange={onChange} />

          <div className="text-center mt-4">
            <Button
              type="primary"
              loading={isLoading}
              onClick={fetchAnalyticsData}
              style={{ marginRight: '5px' }}
            >
              Fetch Analytics
            </Button>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default ViewCampaignAnalytics;
