import React, { useState } from 'react';
import BreadcrumbComponent from '../../components/Admin/CreateAd/BreadcrumbComponent';
import FormComponent from '../../components/Admin/CreateAd/FormComponent';
import { Card, notification } from 'antd';
import API from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';
import { useNavigate } from 'react-router-dom';

const CreateAd = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // auth related redux variables
  const { token } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false); // this variable is to handle loading of form button and the form
  // advertisement data object
  const [adData, setAdData] = useState({
    img_url: '',
    cta_type: '',
    cta_url: '',
    ad_active: false,
    client_id: '',
    button_text: ''
  });
  const key = 'ad_creation';

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.post(
        '/api/ads',
        {
          img_url: adData.img_url,
          cta_type: adData.cta_type,
          cta_url: adData.cta_url,
          ad_active: adData.ad_active,
          client_id: adData.client_id,
          button_text: adData.button_text
        },
        { headers: { Authorization: `Bearer ${token}` } }
      ).then((res) => {
        if (res.status === 201) {
          notification['success']({
            key,
            message: 'Success',
            description: 'Successfully Created an Ad!',
          });
          setLoading(false);
          navigate('/admin/advertisements')
        }
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout())
      } 
    }
  };

  return (
    <div>
      <BreadcrumbComponent />

      <div className="mt-4">
        <Card>
          <div className="mb-4">
            <h5>Create an Advertisement 🖼️</h5>
            <span className="text-muted">
              Please fill below form and create a new advertisement!
            </span>
          </div>
          <FormComponent
            adData={adData}
            setAdData={setAdData}
            loading={loading}
            handleSubmit={handleSubmit}
          />
        </Card>
      </div>
    </div>
  );
};

export default CreateAd;
