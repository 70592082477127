import React from 'react';
import { Breadcrumb } from 'antd';
import { FolderFilled } from '@ant-design/icons';

const BreadcrumbComponent = () => {
  return (
    <Breadcrumb
      items={[
        {
          title: (
            <>
              <FolderFilled />
              <span>Campaigns</span>
            </>
          ),
        },
        {
          title: 'All Campaigns',
        },
      ]}
    />
  );
};

export default BreadcrumbComponent;
