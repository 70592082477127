import React, { useState, useEffect } from 'react';
import { Card, notification, Table } from 'antd';
import BreadcrumbComponent from '../../components/Admin/AllUsers/BreadcrumbComponent';
import API from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';

const AllUsers = () => {
  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const key = 'user_fetch';

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      await API.get('/api/user/all', {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (res.status === 200) {
          setUsers(res.data);
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'userRole',
      key: 'userRole',
    },
  ];

  return (
    <div>
      <BreadcrumbComponent />

      <div className="mt-4">
        <Card>
          <div className="mb-4">
            <h5>All Users 👥</h5>
            <span className="text-muted">
              You can see all the users registered within this portal from here!
            </span>
          </div>

          <div className="mt-4">
            <Table
              dataSource={users}
              columns={columns}
              loading={loading}
              pagination={false}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AllUsers;
