import React, { useState } from 'react';
import BreadcrumbComponent from '../../components/Admin/CreateCampaign/BreadcrumbComponent';
import FormComponent from '../../components/Admin/CreateCampaign/FormComponent';
import QRComponent from '../../components/Admin/CreateCampaign/QRComponent';
import { Card, notification } from 'antd';
import API from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';

const CreateCampaign = () => {
  const dispatch = useDispatch();
  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false); // this variable is to handle loading of form button and the form
  // campaign data object
  const [campaignData, setCampaignData] = useState({
    name: '',
    description: '',
    welcome_card_url: '',
    client_id: '',
    location: '',
  });
  const key = 'campaign_creation';

  const [campaignCreationStep, setCampaignCreationStep] = useState(1); // responsible for campaign creation steps
  const [createdCampaignId, setCreatedCampaignId] = useState(null); // set campaign id after creating the campaign

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.post(
        '/api/campaigns',
        {
          name: campaignData.name,
          description: campaignData.description,
          welcome_card_url: campaignData.welcome_card_url,
          client_id: campaignData.client_id,
          location: campaignData.location,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      ).then((res) => {
        if (res.status === 201) {
          notification['success']({
            key,
            message: 'Success',
            description: 'Successfully Created a Campaign!',
          });
          setLoading(false);
          setCreatedCampaignId(res.data?.id);
          setCampaignCreationStep(2);
        }
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  return (
    <div>
      <BreadcrumbComponent />

      {campaignCreationStep === 1 && (
        <div className="mt-4">
          <Card>
            <div className="mb-4">
              <h5>Create a Campaign 📈</h5>
              <span className="text-muted">
                Please fill below form and create a new campaign!
              </span>
            </div>
            <FormComponent
              campaignData={campaignData}
              setCampaignData={setCampaignData}
              loading={loading}
              handleSubmit={handleSubmit}
            />
          </Card>
        </div>
      )}

      {campaignCreationStep === 2 && (
        <div className="mt-4">
          <Card>
            <div className="mb-4">
              <h5>Campaign Creation Successfull ✅</h5>
              <span className="text-muted">
                Download the QR for the newly created campaign!
              </span>
            </div>

            <QRComponent campaignId={createdCampaignId} />
          </Card>
        </div>
      )}
    </div>
  );
};

export default CreateCampaign;
