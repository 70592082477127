import React, { useState } from 'react';
import BreadcrumbComponent from '../../components/ViewCampaignAnalytics/BreadcrumbComponent';
import { useLocation } from 'react-router-dom';
import { Card, Tooltip, Alert, Drawer, DatePicker, Button } from 'antd';
import { SettingFilled } from '@ant-design/icons';
import API from '../../utils/API';


const ViewAdAnalytics = () => {
  // get url query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const adName = queryParams.get('name');
  const adId = queryParams.get('campaign');

  // analytics response data
  const [isLoading, setIsLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);

  // drawer related variables
  const [open, setOpen] = useState(false);

  // range picker related data
  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onChange = (date, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  // to fetch data from backend
  // TODO: send relevant data inside post request and get analytics data
  // const fetchAnalyticsData = () => {
  //   setIsLoading(true);
  //   /**
  //    * after getting analytics data, call 'onClose' method to close drawer
  //    * make setIsLoading(false)
  //    */
  // }
  const fetchAnalyticsData = async () => {
    try {
      setIsLoading(true);
      const response = await API.get('/api/analytical-data/ads', {
        params: {
          adId: adId,
          startDate: startDate,
          endDate: endDate,
        },
      });
      setAnalyticsData(response.data);
      setIsLoading(false);
      onClose();
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
  };

  return (
    <div>
      <BreadcrumbComponent />

      <div className="mt-4">
        <Card>
          <div className="mb-2">
            <div className="d-flex justify-content-between">
              <div>
                <h5>View Analytics for {adName} 📊</h5>
                <span className="text-muted">
                  Select date range to filter analytics data. Click on{' '}
                  <SettingFilled /> icon to select date range
                </span>
              </div>

              <div>
                <Tooltip title="Select Dates Range">
                  <SettingFilled
                    style={{ fontSize: '25px', cursor: 'pointer' }}
                    onClick={showDrawer}
                  />
                </Tooltip>
              </div>
            </div>

            {
              analyticsData.length > 0 && (
                <div className='mt-5'>
                  {
                    analyticsData.map((data) => (
                      <span>
                        {/* TODO: show analytics data here */}
                      </span>
                    ))
                  }
                </div>
              )
            }
          </div>
        </Card>

        {analyticsData.length === 0 && (
          <Alert
            className="mt-4"
            description="Currently no data available for this campaign for selected dates range."
            type="info"
            showIcon
          />
        )}

        <Drawer
          title="Select Date Range"
          placement="right"
          onClose={onClose}
          open={open}
          width={450}
        >
          <RangePicker showTime onChange={onChange} />

          <div className="text-center mt-4">
            <Button type="primary" loading={isLoading} onClick={fetchAnalyticsData}>
              Fetch Analytics
            </Button>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default ViewAdAnalytics;
