import { Card, notification } from 'antd';
import React, { useState } from 'react';
import BreadcrumbComponent from '../../components/Admin/CreateUser/BreadcrumbComponent';
import FormComponent from '../../components/Admin/CreateUser/FormComponent';
import API from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';
import { useNavigate } from 'react-router-dom';

const CreateUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // auth related redux variables
  const { token } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false); // this variable is to handle loading of form button and the form
  // user data object
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    role: '',
  });
  const key = 'user_creation';

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.post(
        '/api/user/register',
        {
          email: userData.email,
          password: userData.password,
          role: userData.role,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      ).then((res) => {
        if (res.status === 201) {
          notification['success']({
            key,
            message: 'Success',
            description: 'Successfully Created a User!',
          });
          setLoading(false);
          navigate('/admin/users')
        }
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout())
      } 
    }
  };

  return (
    <div>
      <BreadcrumbComponent />

      <div className="mt-4">
        <Card>
          <div className="mb-4">
            <h5>Create a User 👤</h5>
            <span className="text-muted">
              Please fill below form and create a new user by assigning a role!
            </span>
          </div>
          <FormComponent
            userData={userData}
            setUserData={setUserData}
            loading={loading}
            handleSubmit={handleSubmit}
          />
        </Card>
      </div>
    </div>
  );
};

export default CreateUser;
