import React, { useState, useEffect } from 'react';
import { Button, QRCode } from 'antd';

const QRComponent = ({ campaignId }) => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (!campaignId) {
      setStatus('loading');
    } else {
      setStatus('active');
    }
  }, [campaignId]);

  const downloadQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = `QRCode_${campaignId}.png`;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <div id="myqrcode" className="text-center">
      <QRCode
        value={`${process.env.REACT_APP_USER_APP_DOMAIN}/${campaignId}`}
        className="mt-3 mb-3 mx-auto"
        size={250}
        status={status}
      />
      <Button type="primary" onClick={downloadQRCode}>
        Download
      </Button>
    </div>
  );
};

export default QRComponent;
