import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../utils/API';

// initialize userToken from local storage
const token = localStorage.getItem('swype_token')
  ? localStorage.getItem('swype_token')
  : null;

const userRole = localStorage.getItem('swype_role')
? localStorage.getItem('swype_role')
: null;

const initialState = {
  loading: false,
  token,
  error: null,
  userProfile: null,
  userRole,
  success: false,
};

// TODO: modify this according to actual endpoint
export const loginUser = createAsyncThunk('/api/user/login', async (userData) => {
  return API.post('/api/user/login', { "email": userData.email, "password": userData.password}).then(
    (response) => response.data
  );
});

// TODO: modify this according to actual endpoint
export const getUserProfileData = createAsyncThunk(
  '/auth/profile',
  async (token) => {
    return API.get('/auth/profile', {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => response.data.payload);
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.error = null;
      state.success = false;
      state.loading = false;
    },
    logout: (state) => {
      localStorage.removeItem('swype_token');
      localStorage.removeItem('swype_role');
      state.token = null;
      state.userProfile = null;
      state.userRole = null;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
      state.token = null;
      state.userRole = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.token = action.payload.token;
      state.userRole = action.payload.userRole;      
      localStorage.setItem('swype_token', action.payload.token);
      localStorage.setItem('swype_role', action.payload.userRole)
      state.success = true;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = false;
      state.token = null;
      state.userRole = null;
    });

    // profile data
    builder.addCase(getUserProfileData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUserProfileData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.userProfile = action.payload.user;
    });
    builder.addCase(getUserProfileData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { reset, logout } = authSlice.actions;

export default authSlice.reducer;
