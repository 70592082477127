import { Card, Form, Button, Input, notification } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, reset } from '../../Redux/AuthSlice';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  // auth related redux variables
  const { loading, error, success, userRole, token } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const key = 'login';

  useEffect(() => {
    if (error) {
      notification['error']({
        key,
        message: 'Error',
        description: error,
      });
      dispatch(reset());
    }

    if (success) {
      notification['success']({
        key,
        message: 'Success',
        description: 'Welcome!',
      });
      dispatch(reset());
      if (userRole === 'ROLE_ADMIN' && token) {
        navigate('/admin/dashboard');
      } 
      if(userRole === 'ROLE_CLIENT' && token) {
        navigate('/client/dashboard');
      }
    }
  }, [error, success, dispatch, navigate, token, userRole]);

  const onFinish = (values) => {
    localStorage.setItem('swype_user_acc', values.email);
    dispatch(loginUser(values)); // dispatch user login
  };

  return (
    <div className="col-lg-6 col-md-8 col-sm-12 mx-auto mt-5">
      <Card>
        <div className="mb-4">
          <h5>Welcome to Richardson Advertising 👋</h5>
          <span className="text-muted">Please login to your account</span>
        </div>

        <Form name="login" onFinish={onFinish} autoComplete="off">
          <span className="small">Email</span>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The Email field must be a valid email!',
              },
              {
                required: true,
                message: 'The Email field is required!',
              },
            ]}
          >
            <Input placeholder="john@example.com" size="large" />
          </Form.Item>

          <span className="small">Password</span>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'The Password field is required!',
              },
            ]}
          >
            <Input.Password placeholder="Password" size="large" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="col-12"
              loading={loading}
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
