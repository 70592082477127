import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  notification,
  Upload,
  Select,
  Checkbox,
  Spin,
} from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import AWS from 'aws-sdk';
import moment from 'moment';
import { logout } from '../../../Redux/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../utils/API';

// AWS configurations for S3 bucket
AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_S3_BUCKET },
  region: process.env.REACT_APP_REGION,
});

const smsPattern = /^sms:(\+)?\d+&body=.*$/;
const callPattern = /^tel:(\+)?\d+$/;
const websitePattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

const FormComponent = ({ adData, setAdData, loading, handleSubmit }) => {
  const { Option } = Select;
  const [uploadedImage, setUploadedImage] = useState(null); // variable to store uploaded image as a file
  const [isImageUploading, setIsImageUploading] = useState(false); // to indicate image uploading state
  const key = 'image_upload'; // antd notification key

  const [ctaUrlHelperTxt, setCtaUrlHelperTxt] = useState('');
  const [ctaUrlValidateStatus, setCtaUrlValidateStatus] = useState('');

  const [clients, setClients] = useState([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const client_key = 'fetch_clients';

  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const fetchClients = async () => {
    setIsClientsLoading(true);
    try {
      await API.get('/api/user/client', {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (res.status === 200) {
          setClients(res.data);
          setIsClientsLoading(false);
        }
      });
    } catch (error) {
      notification['success']({
        client_key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (adData?.cta_type === 'sms') {
      setCtaUrlHelperTxt('sms:<number>&body=<message>');
      setCtaUrlValidateStatus('warning');
    } else if (adData?.cta_type === 'call') {
      setCtaUrlHelperTxt('tel:<number>');
      setCtaUrlValidateStatus('warning');
    } else if (adData?.cta_type === 'website') {
      setCtaUrlHelperTxt('https://rodl.lk');
      setCtaUrlValidateStatus('warning');
    } else {
      setCtaUrlHelperTxt('');
      setCtaUrlValidateStatus('');
    }
  }, [adData?.cta_url, adData?.cta_type]);

  useEffect(() => {
    if (adData?.cta_type === 'sms') {
      if (smsPattern.test(adData?.cta_url)) {
        setCtaUrlHelperTxt('');
        setCtaUrlValidateStatus('success');
      } else {
        setCtaUrlHelperTxt('sms:<number>&body=<message>');
        setCtaUrlValidateStatus('warning');
      }
    } else if (adData?.cta_type === 'call') {
      if (callPattern.test(adData?.cta_url)) {
        setCtaUrlHelperTxt('');
        setCtaUrlValidateStatus('success');
      } else {
        setCtaUrlHelperTxt('tel:<number>');
        setCtaUrlValidateStatus('warning');
      }
    } else if (adData?.cta_type === 'website') {
      if (websitePattern.test(adData?.cta_url)) {
        setCtaUrlHelperTxt('');
        setCtaUrlValidateStatus('success');
      } else {
        setCtaUrlHelperTxt('https://rodl.lk');
        setCtaUrlValidateStatus('warning');
      }
    } else {
      setCtaUrlHelperTxt('');
      setCtaUrlValidateStatus('');
    }
  }, [adData?.cta_url, adData?.cta_type]);

  // this method will run after submitting the form
  const onFinish = (values) => {
    handleSubmit();
  };

  // upload configurations for antd upload
  const uploadConfigs = {
    onRemove: () => {
      setUploadedImage(null);
      setAdData({ ...adData, img_url: null });
    },
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png';
      const isJPG = file.type === 'image/jpg';
      const isJPEG = file.type === 'image/jpeg';
      const isGIF = file.type === 'image/gif';

      if (isPNG || isJPG || isJPEG || isGIF) {
        let fileSize = file.size / 1024 / 1024; // in MB
        if (fileSize <= 5) {
          setUploadedImage(file);
        } else {
          setUploadedImage(null);
          setAdData({ ...adData, img_url: null });
          notification['error']({
            key,
            message: 'Invalid File Size',
            description: 'File size exceeds 5mb, review and try again.',
          });
        }
      } else {
        notification['error']({
          key,
          message: 'Invalid File Type',
          description: 'Accepted formats are .png, .jpg, .jpeg or .gif.',
        });
        return false;
      }
      return false;
    },
  };

  // this hook will run if there is an uploaded file
  useEffect(() => {
    if (uploadedImage) {
      setAdData({ ...adData, img_url: null });
      // call the method to upload the file to AWS S3
      handleFileUpload();
    }
    // eslint-disable-next-line
  }, [uploadedImage]);

  const handleFileUpload = async () => {
    setIsImageUploading(true);
    try {
      const fileExtension = uploadedImage.name.split('.').pop();
      const timestamp = moment().valueOf();
      const fileName = `${timestamp}.${fileExtension}`;

      const params = {
        ACL: 'public-read',
        Body: uploadedImage,
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: fileName,
      };

      myBucket
        .putObject(params)
        .on('httpUploadProgress', (evt) => {
          const progress = Math.round((evt.loaded / evt.total) * 100);
        })
        .send((error, data) => {
          if (!error) {
            const uploadedFileURI = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`;
            setAdData({ ...adData, img_url: uploadedFileURI });
            setUploadedImage(null);
            setIsImageUploading(false);
            notification['success']({
              key,
              message: 'File Uploaded',
              description: 'Successfully uploaded your image.',
            });
          }
        });
    } catch (error) {
      setIsImageUploading(false);
      setUploadedImage(null);
      setAdData({ ...adData, img_url: null });
      notification['error']({
        key,
        message: 'File Upload Error',
        description: error.message,
      });
    }
  };

  const uploadButton = (
    <div>
      {isImageUploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div>
      <Form
        name="ad_form"
        onFinish={onFinish}
        initialValues={{
          img_url: adData.img_url,
          cta_type: adData.cta_type,
          cta_url: adData.cta_url,
          client_id: adData.client_id,
          ad_active: adData.ad_active,
          button_text: adData.button_text,
        }}
      >
        <span className="small fw-bold text-muted">
          Upload the Advertisement as an Image
        </span>
        <br />
        <span style={{ fontSize: '11px', color: '#f5222d' }}>
          (Image should be in 16:9 aspect ratio)
        </span>
        <Form.Item
          name="img_url"
          rules={[
            {
              required: true,
              message: 'Image is required!',
            },
          ]}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            {...uploadConfigs}
            maxCount={1}
            accept=".png, .jpeg, .jpg, .gif "
          >
            {adData?.img_url ? (
              <img
                src={adData?.img_url}
                alt="avatar"
                style={{
                  height: '100%',
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <span className="small fw-bold text-muted">
          Call To Action Button Type
        </span>
        <Form.Item
          name="cta_type"
          rules={[
            {
              required: true,
              message: 'Please select the butto  type!',
            },
          ]}
        >
          <Select
            placeholder="Please select button type"
            onChange={(value) => setAdData({ ...adData, cta_type: value })}
            allowClear
            disabled={isImageUploading}
          >
            <Option value="website">Website</Option>
            <Option value="sms">SMS</Option>
            <Option value="call">Call</Option>
            <Option value="whatsapp">Whatsapp</Option>
            <Option value="custom">Custom</Option>
          </Select>
        </Form.Item>

        <span className="small fw-bold text-muted">
          Call To Action Button URL
        </span>
        <Form.Item
          name="cta_url"
          hasFeedback
          validateStatus={ctaUrlValidateStatus}
          help={ctaUrlHelperTxt}
        >
          <Input
            value={adData.cta_url}
            onChange={(e) => setAdData({ ...adData, cta_url: e.target.value })}
            placeholder="Please enter the url in correct format"
            disabled={isImageUploading}
          />
        </Form.Item>

        <span className="small fw-bold text-muted">
          Call To Action Button Text
        </span>
        <Form.Item name="button_text">
          <Input
            value={adData.button_text}
            onChange={(e) =>
              setAdData({ ...adData, button_text: e.target.value })
            }
            placeholder="ex: Visit Website"
            disabled={isImageUploading}
          />
        </Form.Item>

        <span className="small fw-bold text-muted">Client</span>
        {isClientsLoading ? (
          <Form.Item>
            <Spin size="small" />
          </Form.Item>
        ) : (
          <Form.Item
            name="client_id"
            rules={[
              {
                required: true,
                message: 'Please select the client!',
              },
            ]}
          >
            <Select
              placeholder="Please select Client"
              onChange={(value) => setAdData({ ...adData, client_id: value })}
              allowClear
              disabled={isImageUploading}
            >
              {clients.map((client) => (
                <Option key={client.id} value={client.id}>
                  {client.email}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item name="ad_active" valuePropName="checked">
          <Checkbox
            className="small fw-bold text-muted"
            value={adData.ad_active}
            onChange={(e) =>
              setAdData({ ...adData, ad_active: e.target.checked })
            }
            disabled={isImageUploading}
          >
            Is advertisement active
          </Checkbox>
        </Form.Item>

        <Form.Item className="text-center">
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormComponent;
