import React, { useState, useEffect } from 'react';
import API from '../../../utils/API';
import { Form, Input, Button, notification, Upload, Select, Spin } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'reactstrap';
import AWS from 'aws-sdk';
import moment from 'moment';
import { logout } from '../../../Redux/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

// AWS configurations for S3 bucket
AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_S3_BUCKET },
  region: process.env.REACT_APP_REGION,
});

const FormComponent = ({
  campaignData,
  setCampaignData,
  loading,
  handleSubmit,
}) => {
  const [uploadedWelcomeCard, setUploadedWelcomeCard] = useState(null);
  const [welcomeCardUploading, setWelcomeCardUploading] = useState(false);
  const key = 'image_upload';

  const [clients, setClients] = useState([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const client_key = 'fetch_clients';

  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // this method will run after submitting the form
  const onFinish = (values) => {
    handleSubmit();
  };

  // upload configurations for welcom card upload
  const uploadWelcomeCardConfigs = {
    onRemove: () => {
      setUploadedWelcomeCard(null);
      setCampaignData({ ...campaignData, welcome_card_url: null });
    },
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png';
      const isJPG = file.type === 'image/jpg';
      const isJPEG = file.type === 'image/jpeg';
      const isGIF = file.type === 'image/gif';

      if (isPNG || isJPG || isJPEG || isGIF) {
        let fileSize = file.size / 1024 / 1024; // in MB
        if (fileSize <= 5) {
          setUploadedWelcomeCard(file);
        } else {
          setUploadedWelcomeCard(null);
          setCampaignData({ ...campaignData, welcome_card_url: null });
          notification['error']({
            key,
            message: 'Invalid File Size',
            description: 'File size exceeds 5mb, review and try again.',
          });
        }
      } else {
        notification['error']({
          key,
          message: 'Invalid File Type',
          description: 'Accepted formats are .png, .jpg, .jpeg or .gif.',
        });
        return false;
      }
      return false;
    },
  };

  useEffect(() => {
    if (uploadedWelcomeCard) {
      setCampaignData({ ...campaignData, welcome_card_url: null });
      handleWelcomeCardUpload();
    }
    // eslint-disable-next-line
  }, [uploadedWelcomeCard]);

  const fetchClients = async () => {
    setIsClientsLoading(true);
    try {
      await API.get('/api/user/client', {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (res.status === 200) {
          setClients(res.data);
          setIsClientsLoading(false);
        }
      });
    } catch (error) {
      notification['success']({
        client_key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleWelcomeCardUpload = async () => {
    setWelcomeCardUploading(true);
    try {
      const fileExtension = uploadedWelcomeCard.name.split('.').pop();
      const timestamp = moment().valueOf();
      const fileName = `${timestamp}.${fileExtension}`;

      const params = {
        ACL: 'public-read',
        Body: uploadedWelcomeCard,
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: fileName,
      };

      myBucket
        .putObject(params)
        .on('httpUploadProgress', (evt) => {
          const progress = Math.round((evt.loaded / evt.total) * 100);
        })
        .send((error, data) => {
          if (!error) {
            const uploadedFileURI = `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${fileName}`;
            setCampaignData({
              ...campaignData,
              welcome_card_url: uploadedFileURI,
            });
            setUploadedWelcomeCard(null);
            setWelcomeCardUploading(false);
            notification['success']({
              key,
              message: 'File Uploaded',
              description: 'Successfully uploaded your image.',
            });
          }
        });
    } catch (error) {
      setCampaignData({ ...campaignData, welcome_card_url: null });
      setWelcomeCardUploading(false);
      setUploadedWelcomeCard(null);
      notification['error']({
        key,
        message: 'File Upload Error',
        description: error.message,
      });
    }
  };

  const welcomeCardUploadButton = (
    <div>
      {welcomeCardUploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div>
      <Form
        name="campaign_form"
        onFinish={onFinish}
        initialValues={{
          name: campaignData.name,
          description: campaignData.description,
          welcome_card_url: campaignData.welcome_card_url,
          logo_url: campaignData.logo_url,
          client_id: campaignData.client_id,
          location: campaignData.location
        }}
      >
        <span className="small fw-bold text-muted">Campaign Name</span>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Campaign name is required!',
            },
          ]}
        >
          <Input
            value={campaignData.name}
            onChange={(e) =>
              setCampaignData({ ...campaignData, name: e.target.value })
            }
            placeholder="Please input campaign name"
            disabled={welcomeCardUploading}
          />
        </Form.Item>

        <span className="small fw-bold text-muted">Campaign Description</span>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: 'Campaign description is required!',
            },
          ]}
        >
          <Input.TextArea
            value={campaignData.description}
            rows={5}
            onChange={(e) =>
              setCampaignData({ ...campaignData, description: e.target.value })
            }
            placeholder="Please provide a description about campaign"
            disabled={welcomeCardUploading}
          />
        </Form.Item>

        <Row>
          <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="6">
            <span className="small fw-bold text-muted">
              Upload the Welcome Card as an Image
            </span>
            <br />
            <span style={{ fontSize: '11px', color: '#f5222d' }}>
              (Image should be in 16:9 aspect ratio)
            </span>
            <Form.Item
              name="welcome_card_url"
              rules={[
                {
                  required: true,
                  message: 'Welcome Card is required!',
                },
              ]}
            >
              <Upload
                name="welcome"
                listType="picture-card"
                showUploadList={false}
                {...uploadWelcomeCardConfigs}
                maxCount={1}
                accept=".png, .jpeg, .jpg, .gif "
              >
                {campaignData?.welcome_card_url ? (
                  <img
                    src={campaignData?.welcome_card_url}
                    alt="welcome_card_url"
                    style={{
                      height: '100%',
                    }}
                  />
                ) : (
                  welcomeCardUploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <span className="small fw-bold text-muted">Client</span>
        {isClientsLoading ? (
          <Form.Item>
            <Spin size="small" />
          </Form.Item>
        ) : (
          <Form.Item
            name="client_id"
            rules={[
              {
                required: true,
                message: 'Please select the client!',
              },
            ]}
          >
            <Select
              placeholder="Please select Client"
              onChange={(value) =>
                setCampaignData({ ...campaignData, client_id: value })
              }
              allowClear
              disabled={welcomeCardUploading}
            >
              {clients.map((client) => (
                <Option key={client.id} value={client.id}>
                  {client.email}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <span className="small fw-bold text-muted">Location</span>
        <Form.Item
          name="location"
          rules={[
            {
              required: true,
              message: 'Location is required!',
            },
          ]}
        >
          <Input
            value={campaignData.location}
            onChange={(e) =>
              setCampaignData({ ...campaignData, location: e.target.value })
            }
            placeholder="Please input location"
            disabled={welcomeCardUploading}
          />
        </Form.Item>

        <Form.Item className="text-center">
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormComponent;
