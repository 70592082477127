import React, { useState, useEffect } from 'react';
import { Card, notification, Spin } from 'antd';
import { Col, Row } from 'reactstrap';
import API from '../../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../Redux/AuthSlice';
import { EnvironmentFilled } from '@ant-design/icons';

const RecentCampaigns = () => {
  const { Meta } = Card;
  const key = 'fetch_campaigns';

  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      await API.get('/api/campaigns', {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (res.data.length > 4) {
          const recentArray = res.data.slice(0, 4);
          setCampaigns(recentArray);
        } else {
          setCampaigns(res.data);
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  return (
    <div>
      <div className="mt-4">
        <Card>
          <div className="mb-2">
            <h5>Recent Campaigns 🚀</h5>
            <span className="text-muted">
              These are the recent campaigns you have created
            </span>
          </div>

          {loading ? (
            <div className="text-center mt-5">
              <Spin />
            </div>
          ) : (
            <Row>
              {campaigns.map((campaign) => (
                <Col
                  xxl="3"
                  xl="3"
                  lg="3"
                  md="4"
                  sm="6"
                  xs="12"
                  className="mt-4"
                  key={campaign.id}
                >
                  <Card
                    hoverable
                    cover={
                      <img
                        alt="example"
                        src={campaign?.welcome_card_url}
                        style={{ height: '250px' }}
                      />
                    }
                  >
                    <Meta
                      title={campaign?.name}
                      description={
                        <>
                          <span>{campaign?.description}</span>
                          <br />
                          <span className="text-dark">
                            <EnvironmentFilled />
                            {` `}
                            {campaign?.location}
                          </span>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </Card>
      </div>
    </div>
  );
};

export default RecentCampaigns;
