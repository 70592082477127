import { Layout } from 'antd';
import Logo from '../../assets/richardson-logo.png';

const { Header } = Layout;

const GuestHeader = () => {
  return (
    <Header
    style={{
      padding: 0,
    }}
    >
      <img src={Logo} style={{ width: '150px', marginLeft: '20px' }} alt="sider-logo" />
    </Header>
  )
}

export default GuestHeader