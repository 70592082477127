import { Form, Input, Button, Select } from 'antd';
import React from 'react';

// thid form is a re-usable component for create user and edit user
const FormComponent = ({ userData, setUserData, loading, handleSubmit }) => {
  const { Option } = Select;
  const onFinish = (values) => {
    handleSubmit();
  };

  return (
    <div>
      <Form name="user_form" onFinish={onFinish}>
        <span className="small fw-bold text-muted">Email</span>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Please input a correct email!',
            },
            {
              required: true,
              message: 'Please input user email!',
            },
          ]}
        >
          <Input
            placeholder="john@example.com"
            value={userData.email}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
          />
        </Form.Item>

        <span className="small fw-bold text-muted">Password</span>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password
            placeholder="Password"
            value={userData.password}
            onChange={(e) =>
              setUserData({ ...userData, password: e.target.value })
            }
          />
        </Form.Item>

        <span className="small fw-bold text-muted">Confirm Password</span>
        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The new password that you entered do not match!')
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <span className="small fw-bold text-muted">User Role</span>
        <Form.Item
          name="role"
          rules={[
            {
              required: true,
              message: 'Please select the user role!',
            },
          ]}
        >
          <Select
            placeholder="Select user role"
            onChange={(value) => setUserData({ ...userData, role: value })}
            allowClear
          >
            <Option value="ROLE_ADMIN">Admin</Option>
            <Option value="ROLE_CLIENT">Client</Option>
          </Select>
        </Form.Item>

        <Form.Item className="text-center">
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormComponent;
