import {
  PictureFilled,
  FolderFilled,
  UserOutlined,
  AppstoreFilled
} from '@ant-design/icons';

// only returns menu items related to user type
function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
}

export const items = [
  getItem('Dashboard', '/admin/dashboard', <AppstoreFilled />),
  getItem('Ads', 'sub1', <PictureFilled />, [
    getItem('All Ads', '/admin/advertisements'),
    getItem('Create', '/admin/create-advertisement'),
  ]),
  getItem('Campaigns', 'sub2', <FolderFilled />, [
    getItem('All Campaigns', '/admin/campaigns'),
    getItem('Create', '/admin/create-campaign'),
  ]),
  getItem('Users', 'sub3', <UserOutlined />, [
    getItem('All Users', '/admin/users'),
    getItem('Create', '/admin/create-user'),
  ]),
];
