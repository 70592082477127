import React from 'react';
import { Breadcrumb } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const BreadcrumbComponent = () => {
  return (
    <Breadcrumb
      items={[
        {
          title: (
            <>
              <UserOutlined />
              <span>Users</span>
            </>
          ),
        },
        {
          title: 'All Users',
        },
      ]}
    />
  );
};

export default BreadcrumbComponent;
