import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BreadcrumbComponent from '../../components/Admin/UpdateCampaign/BreadcrumbComponent';
import FormComponent from '../../components/Admin/CreateCampaign/FormComponent';
import { Card, notification, Spin, Popconfirm } from 'antd';
import API from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { DeleteFilled } from '@ant-design/icons';

const UpdateCampaign = () => {
  const key = 'campaign_fetch';
  const update_key = 'update_campaign';
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // auth related redux variables
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [campaignDataLoading, setCampaignDataLoading] = useState(false);
  // campaign data object
  const [campaignData, setCampaignData] = useState('');

  const [isCampaignDeleting, setIsCampaignDeleting] = useState(false)

  useEffect(() => {
    fetchCampaignData();
  }, [campaignId, dispatch, token]);

  const fetchCampaignData = async () => {
    setCampaignDataLoading(true);
    try {
      await API.get(`/api/campaigns/${campaignId}`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (res.status === 200) {
          setCampaignData(res.data);
          setCampaignDataLoading(false);
        }
      });
    } catch (error) {
      setCampaignDataLoading(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.put(
        `/api/campaigns/${campaignId}`,
        {
          name: campaignData.name,
          description: campaignData.description,
          welcome_card_url: campaignData.welcome_card_url,
          client_id: campaignData.client_id,
          location: campaignData.location,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      ).then((res) => {
        if (res.status === 200) {
          notification['success']({
            update_key,
            message: 'Success',
            description: 'Successfully Updated!',
          });
          setLoading(false);
          navigate('/admin/campaigns');
        }
      });
    } catch (error) {
      setLoading(false);
      notification['error']({
        update_key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };


  const confirm = (e) => {
    deleteCampaign();
  };

  const cancel = (e) => {};

  const deleteCampaign = async () => {
    try {
      setIsCampaignDeleting(true);
      await API.delete(`/api/campaigns/${campaignId}`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if (res.status === 200) {
          notification['success']({
            update_key,
            message: 'Success',
            description: 'Successfully Deleted the Campaign!',
          });
          setIsCampaignDeleting(false);
          navigate('/admin/campaigns');
        }
      });
    } catch (error) {
      setIsCampaignDeleting(false);
      notification['error']({
        key,
        message: error.response.statusText,
        description: error.response.data.message,
      });
      if (error.response.status === 401) {
        // logout user
        dispatch(logout());
      }
    }
  };

  return (
    <div>
      <BreadcrumbComponent />

      <div className="mt-4">
        <Card>
          <div className="d-flex justify-content-between">
            <div className="mb-4">
              <h5>Update Campaign 📈</h5>
              <span className="text-muted">
                Please update the campaign information below!
              </span>
            </div>

            <div>
              {isCampaignDeleting ? (
                <Spin />
              ) : (
                <Popconfirm
                  title="Delete the Advertisement"
                  description="Are you sure to delete this advertisement?"
                  onConfirm={confirm}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteFilled
                    style={{
                      fontSize: '18px',
                      color: '#f5222d',
                      cursor: 'pointer',
                    }}
                  />
                </Popconfirm>
              )}
            </div>
          </div>

          {campaignDataLoading ? (
            <div className="text-center mt-5">
              <Spin />
            </div>
          ) : (
            <FormComponent
              campaignData={campaignData}
              setCampaignData={setCampaignData}
              loading={loading}
              handleSubmit={handleSubmit}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default UpdateCampaign;
