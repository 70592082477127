import { Layout } from 'antd';
import AdminMenuComponent from './AdminMenuComponent';
import ClientMenuComponent from './ClientMenuComponent';
import Logo from '../../assets/richardson-logo.png';
import { useSelector } from 'react-redux';

const { Sider } = Layout;

const SiderComponent = () => {
  const { userRole } = useSelector((state) => state.auth);

  return (
    <Sider style={{ zIndex: 2, position: 'fixed', minHeight: '100vh' }}>
      <div className="p-4">
        <img src={Logo} style={{ width: '150px' }} alt="sider-logo" />
      </div>
      {userRole === 'ROLE_ADMIN' && <AdminMenuComponent />}

      {userRole === 'ROLE_CLIENT' && <ClientMenuComponent />}
    </Sider>
  );
};

export default SiderComponent;
